export default {
  methods: {
    expandPlayList(playlistID){
      console.log("expandPlayList")
      console.log("this", this)
      /* eslint no-unused-vars: 0 */
      /* eslint no-undef: 0 */
      /* eslint no-unused-labels: 0 */

      let expandedFlag = false

      // 2. This code loads the IFrame Player API code asynchronously.
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      console.log("this.$isYouTubeIframeAPIReady",this.$isYouTubeIframeAPIReady)
      if (!this.$isYouTubeIframeAPIReady){
        this.$window.onYouTubeIframeAPIReady = ()=>{
          new YT.Player('player2', {
            height: '0',
            width: '0',
            events: {
              'onReady': onPlayerReady,
              'onStateChange': onPlayerStateChange.bind(this)
            }
          });
          this.$isYouTubeIframeAPIReady = true
        }
      } else {
        new YT.Player('player2', {
          height: '0',
          width: '0',
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange.bind(this)
          }
        });
      }

      // 4. The API will call this function when the video player is ready.
      function onPlayerReady(event) {
        console.log("onPlayerReady")
        var player = event.target
        player.loadPlaylist({list:playlistID, listType:"playlist"})
        player.stopVideo()
        
        //event.target.playVideo();
      }

      // 5. The API calls this function when the player's state changes.
      //    The function indicates that when playing a video (state=1),
      //    the player should play for six seconds and then stop.
      function onPlayerStateChange(event) {
        if (!expandedFlag){
          expandedFlag = true
          var player = event.target
          console.log("event.data", event.data)
          console.log("this",this)
          console.log("contents", player.getPlaylist())
          this.contents = player.getPlaylist()
          this.setItems()
          player.stopVideo()
          player.destroy()
        }
      }
      function stopVideo() {
        player.stopVideo();
      }

    },
  }
}