<template>
  <v-card
    class="ma-auto"
    max-width="500"
  >
    <v-card-title
      class="headline grey lighten-2 subtitle-1"
      primary-title
    >
      <span> Control </span>
    </v-card-title>

    <v-card-text>
      <Control/>
    </v-card-text>

    <v-card-actions>
    </v-card-actions>

  </v-card>
</template>

<script>
  import Control from '@/components/Control'
  export default {
    props: {
//      path: String
    },
    components: {
      Control
    }
  }

/*
  import axios from 'axios'
  var sprintf = require('sprintf-js').sprintf

  export default {
    data: () => ({
      urlstr: "",
    }),
    methods: {
      send: async function(action){
        console.log("action", action)
        const url = sprintf("/%s", action)
        const res = await axios.get(url)
        console.log("res", res)
      },
    },
    mounted(){
      this.send("connect")
    }
  }
*/
</script>