<template>
  <v-card
    class="ma-auto"
    max-width="500"
  >
    <v-card-title
      class="headline grey lighten-2 subtitle-1"
      primary-title
    >
      <span> Settings </span>
    </v-card-title>

    <v-card-text>
      <v-card-subtitle>Home Position Setting</v-card-subtitle>
      <HomeSelector/>
    </v-card-text>

    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import HomeSelector from '@/components/settings/HomeSelector'
export default {
  components: { HomeSelector},
};
</script>