<template>
  <v-list-item
    @click="moveToPlayList(item)"
  >
    <v-list-item-content>
      <v-list-item-title>
        <span
        >
          {{ item.text }}
        </span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  components: {
  },
  props: {
    item: Object,
  },
  data: () => ({
  }),
  methods: {
    /*
    requestExpandPlayList: function(content, text){
      let url = (new URL("/expandPlayList/" + content + "/" + text, location.href)).toString()
      fetch(url)

      // start Expanding
      this.isExpanding = true
    },*/
    moveToPlayList: function(item){
      console.log("item", item)
      this.$router.push({path: "/ytplaylist/" + item.content, query: {title: item.text }}).catch(()=>{});
    },
/*    confirmIfExpanded: async function(){
      let url = (new URL("/isExpanded/" + this.item.content, location.href)).toString()
      let res = await fetch(url)
      if (res.ok){
        let data = await res.json();
        this.isExpanded = data
      } else {
        console.log(res.status, res.statusText)
        this.isExpanded = false
      }
      console.log("isExpanded", this.isExpanded)
    },
    confirmIfExpanding: async function(){
      let url = (new URL("/isExpanding/" + this.item.content, location.href)).toString()
      let res = await fetch(url)
      if (res.ok){
        let data = await res.json();
        this.isExpanding = data
      } else {
        console.log(res.status, res.statusText)
        this.isExpanding = false
      }
//      console.log("isExpanding", this.isExpanding)
    },
    expandFinishedOK: function(){
      console.log("expandFinishedOK")
      this.isExpanded =  true
      this.isExpanding = false
    },
    expandFinishedNG: function(){
      console.log("expandFinishedNG")
      this.isExpanded =  false
      this.isExpanding = false
    }*/
  },
  watch: {
    item: function(newItem){
      console.log("newItem",newItem)
    },    
  },
  computed: {
  },
  mounted() {
  },
}
</script>