import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueYoutube from 'vue-youtube'

// globals
import home from '/src/mixins/globals/home.js'
import isYTAPIReady from '@/mixins/globals/isYoutubeIframeAPIReady'
import ws from '/src/mixins/globals/ws.js'

Vue.config.productionTip = false
Vue.use(VueYoutube)

// globals
Vue.mixin({
  computed: ws.init()
})
Vue.mixin({
  computed: home.init()
})
Vue.mixin({
  computed: isYTAPIReady.init()
})

/*global fromHS2*/
//console.log("fromHS2",fromHS2)
if (typeof fromHS2 != 'undefined'){
  Vue.prototype.$fromHS2 = fromHS2
} else {
  Vue.prototype.$fromHS2 = false
}
console.log("Vue.prototype.$fromHS2",Vue.prototype.$fromHS2)

// window is a built-in global variable
Vue.prototype.$window = window

Vue.prototype.$YTplayer = ""

new Vue({
  router,
  vuetify,
  data: () => ({
    home: this.$home,
    textFieldValue: 0,
  }),
  render: h => h(App)
}).$mount('#app')