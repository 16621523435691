// for Global Mixin
// https://v2.vuejs.org/v2/guide/mixins.html?redirect=true#Global-Mixin

import Vue from 'vue'

export default {
  init: () => {
    let globalData = new Vue({
      data: {
        $isYouTubeIframeAPIReady: false
      }
    });
    let computed = {
      $isYouTubeIframeAPIReady: {
        get: function () { return globalData.$data.$isYouTubeIframeAPIReady },
        set: function (status) { globalData.$data.$isYouTubeIframeAPIReady = status; }
      },
    };
    return computed
  },
}