<template>
  <v-snackbar
    v-model="snackbar"
    ref="snackbar"
  >
    {{ snackbar_message }}
  </v-snackbar>
</template>

<script>
export default {
  data: function(){
    return {
      snackbar: false,
      snackbar_message: ""
    }
  },
  watch: {
    $message: function(newMessage){
      if (this.$fromHS2){
        console.log("message", newMessage)
        if (newMessage.status == "ytdlp"){
          // show Playback Preparing message
          this.snackbar = true
          this.snackbar_message = "Playback Preparing (" + newMessage.state + "/38)"
        }
      }
    }
  }
}
</script>
