import axios from 'axios'

export default {
  methods: {
    AskLatestPlayBackPositions: async function() {
      // update items with the status of viewing 
      if (this.$fromHS2){
        const url = "/updateStatusOfViewing" 
        { 
          const res = await axios.post(url, 
            {items: this.items}
          , {timeout: 1000})
          if (res){
            console.log("res", res)
            if (res.data.items){
              this.items = res.data.items
            }
            console.log("items", this.items)
          }
        }
      }
    }
  }
}