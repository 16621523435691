// for Global Mixin
// https://v2.vuejs.org/v2/guide/mixins.html?redirect=true#Global-Mixin

import Vue from 'vue'

export default {
  init: () => {
    let globalData = new Vue({
      data: {
        $ws: "",
        $message: "",
      }
    });
    let computed = {
      $ws: {
        get: function () { return globalData.$data.$ws },
        set: function (newWS) { globalData.$data.$ws = newWS; }
      },
      $message: {
        get: function () { return globalData.$data.$message },
        set: function (newMessage) { globalData.$data.$message = newMessage; }
      },
    };
    return computed
  },
}