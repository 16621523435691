<template>
  <!-- refer https://neos21.net/blog/2020/07/23-01.html#v-list-item-%E3%81%A7%E7%9C%81%E7%95%A5%E3%81%9B%E3%81%9A%E6%8A%98%E3%82%8A%E8%BF%94%E3%81%97%E8%A1%A8%E7%A4%BA%E3%81%95%E3%81%9B%E3%81%9F%E3%81%84 -->
  <div style="word-break: break-all hyphens: auto; white-space: normal;">
    {{title}}
  </div>
</template>

<script>
import axios from 'axios'
var sprintf = require('sprintf-js').sprintf

export default {
  props: {
    item: Object,
  },
  data: () => ({
    title: "",
  }),
  methods: {
    aho: function(contents){
      return this.title(contents)
    },
    updateTitle: async function(contents){
      const url = sprintf("https://noembed.com/embed?url=https://www.youtube.com/watch?v=%s", contents)
      const res = await axios.get(url)
      if (res.status == 200){
        if (res.data.error){
          console.log("error", res.data.error)
          this.title = this.item.text
        } else {
          this.title = res.data.title
        }
      }
    }
  },
  mounted : function(){
    this.updateTitle(this.item.content)
  },
  watch: {
    item: function(newItem){
      this.updateTitle(newItem.content)
    },    
  },

}
</script>
