import Dexie from 'dexie';

export const db = new Dexie("ContentsDB");
db.version(1).stores({
  Contents: `id, played`,
});
        // save content ID as it has been played before
        // localStorage.setItem(this.item.content, true)
/*
        let dbName = "Contents"
        let version = 1
        let openRequest = indexedDB.open(dbName, version);

        openRequest.onupgradeneeded = function() {
          let db = openRequest.result;
          if (!db.objectStoreNames.contains('Contents')) {
            db.createObjectStore('Contents', {keyPath: 'id'});
          }

          
        };
        */
