<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
<!--    <v-list>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
      >
        <Menu v-if='"link" in item' 
          :item="item"
          @move-request="redirect"
        />
        <Content2 v-if='"content" in item' :item="item"/>
      </v-list-item>
    </v-list> -->
    <v-card-title>{{title}}</v-card-title>
    <!--
    <v-card-subtitle v-if="ref">
      <a :href="ref" target="_blank" rel="noopener noreferrer">refer</a>
    </v-card-subtitle>
    -->
    
    <v-expansion-panels
      v-model="openedPanel" 
      accordion tile
    >
      <v-expansion-panel
        v-for="(item, i) in items"
        :key="i"
      >

        <Menu v-if='"link" in item' 
          :item="item"
          @move-request="redirect"
        />
        <Content v-if='"content" in item' :item="item"/>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>


<script>
import Content from "./Content.vue"
import Menu from "./Menu.vue"
import axios from 'axios'
// var sprintf = require('sprintf-js').sprintf

export default {
  components: {
    Content,
    Menu
  },
  props: {
    path: String,
    query: String,
  },
  data: () => ({
    title: "",
    ref: "",
    items: "",
    base_url: "/getPlayList/", /* https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main/ */
    openedPanel: ""
  }),
  methods: {
    move: async function(path){
      // get items from server
      console.log("this.query",this.query)
      if (this.query.base_url){
        this.base_url = this.query.base_url
      }
//      let url = "/getPlayList/" + encodeURIComponent(path)
      let url = this.base_url + encodeURIComponent(path)
      console.log("url",url)
      const res = await axios.get(url)
      console.log("res", res)
      this.items = res.data.items
      if (res.data.title){
        this.title = res.data.title
        console.log("title", this.title)
      }
      if (res.data.ref){
        this.ref = res.data.ref
      }

      // update items with the status of viewing 
      url = "/updateStatusOfViewing" 
      { 
        const res = await axios.post(url, 
          {items: this.items}
        , {timeout: 1000})
        if (res){
          console.log("res", res)
          if (res.data.items){
            this.items = res.data.items
          }
          console.log("items", this.items)
        }
      }
    },
    redirect: function(p){
      this.$router.push({path: "/channel/"+p, query: { base_url: this.base_url }})
    } 
  },
  watch: {
    path(newVal){
      this.move(newVal)
    },
    openedPanel(newVal){
      console.log("openedPanel:", newVal)
    }
  },
  async mounted() {
    if (this.$props.path){
      this.move(this.$props.path)
    }
  },
}
</script>