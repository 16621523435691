import Vue from 'vue'
import VueRouter from 'vue-router'
import Home2 from '@/views/Home2.vue'
import Home from '@/views/Home.vue'
import YTPlayList from '@/views/YTPlayList.vue'
import Play from '@/views/Play.vue'
import Control from '@/views/Control.vue'
import Settings from '@/views/Settings.vue'
import SetHome from '@/views/SetHome.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
//    redirect: '/channel/home',
    beforeEnter: (to, from, next) => {
//      next({ path: '/channel/home', query: {base_url: 'https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main/'} })
      next({ path: '/channel2/home'})
    },
    component: Home
  },
  {
    path: '/channel2/*',
    name: 'Home2',
    component: Home2
  },
  {
    path: '/channel/*',
    name: 'Home',
    component: Home
  },
  {
    path: '/ytplaylist/:id',
    name: 'YTPlayList',
    component: YTPlayList
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/play',
    name: 'Play',
    component: Play
  },
  {
    path: '/control',
    name: 'Control',
    component: Control
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/sethome',
    name: 'SetHome',
    component: SetHome
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
