// For Websocket connection

export default {
  methods: {
    wsStart: function(){
      console.log("WebSocket Connection Start")
      this.$ws = new WebSocket("ws://" + window.location.host + "/ws")
      this.$ws.onerror = function(event) {
        console.error("Grobal WebSocket error observed:", event);
      };
      this.$ws.onclose = (event) => {
        console.log("Grobal WebSocket is closed:", event);
        this.wsStart()
      };
      this.$ws.onopen = (event) => {
        console.log(event)
        console.log("Grobal WebSocket is opened As:",this.$ws)
      };
      this.$ws.onmessage = async (event) => {
        console.log("Grobal WebSocket onmessage event:",event)

        // parse event to message
        let message = JSON.parse(event.data)
        console.log("Grobal WebSocket message", message)

        // save message to the Global variable
        this.$message = message
      }
    }
  }
}