<template :v-if="contentsPath">
  <PlayMenu :path="contentsPath" :query="query" ref="playlist"/>
</template>

<script>
  import PlayMenu from '@/components/PlayMenu'
  export default {
    props: {
//      path: String
    },
    components: {
      PlayMenu,
    },
    data: () => ({
      contentsPath: "",
      query: ""
    }),
    methods: {
      setContentsPath(query, params){
        console.log("query",query)
        console.log("params",params)
        if (query.path) {
          console.log("query.path",query.path)
          this.contentsPath = query.path
        } else if (params.pathMatch){
          console.log("params",params)
          console.log("params.pathMatch",params.pathMatch)
          this.contentsPath = params.pathMatch
          this.query = query
          console.log()
        } else {
          console.log("no query, nor params")
          this.contentsPath = "home"
        }
      }
    },
/*    computed: {
     contentsPath(){
        console.log(this)
        if (this.$route.query.path) {
          console.log("$route.query.path",this.$route.query.path)
          return this.$route.query.path
        } else if (this.$route.params.pathMatch){
          console.log("$route.params.pathMatch",this.$route.params.pathMatch)
          return this.$route.params.pathMatch
        } else {
          console.log(this)
          return "home"
        }
      }
    },*/
    watch: {
      $route(to) {
        console.log(to)
        this.$refs.playlist.openedPanel = ""
        this.setContentsPath(to.query, to.params)
/*
        if (to.query.path) {
          console.log("to.query.path",to.query.path)
          this.contentsPath = to.query.path
        } else if (to.params.pathMatch){
          console.log("to.params.pathMatch",to.params.pathMatch)
          this.contentsPath = to.params.pathMatch
          console.log()
        } else {
          console.log("to",to)
          this.contentsPath = "home"
        }
*/
      }
    },
    mounted: function(){
      console.log("route", this.$route)
      this.setContentsPath(this.$route.query, this.$route.params)
      /*
      if (this.$route.query.path) {
        console.log("this.$route",this.$route.query.path)
        this.contentsPath = this.$route.query.path
      } else if (this.$route.params.pathMatch){
        console.log("this.$route.params.pathMatch",this.$route.params.pathMatch)
        this.contentsPath = this.$route.params.pathMatch
        console.log()
      } else {
        console.log("this.$route",this.$route)
        this.contentsPath = "home"
      }
      */
    }
  }
</script>