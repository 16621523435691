<template>
  <v-select
    v-model="home"
    :items="homeSelectors"
    label="home"
  ></v-select>
</template>

<script>
  const homeSelectors = ['local', 'github/UedaTakeyuki/HS2playlist']
  export default {
    data: () => ({
      homeSelectors: homeSelectors,
      home: "github/UedaTakeyuki/HS2playlist"
    }),
    watch: {
      // eslint no-unused-vars
      home: function (newhome/*, oldlang*/) {
        // set to global
        switch (newhome) {
          case homeSelectors[0]:
            this.$home = (new URL(location.href, "/getPlayList/home").toString())
            this.$base_url = (new URL(location.href, "/getPlayList").toString())
            break;
          case homeSelectors[1]:
            this.$home = "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main/home"
            this.$base_url = "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main"
            break;
          default:
          this.$home = "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main/home"
          this.$base_url = "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main"
        }
      }
    }
  }
</script>