<template>
  <v-card
    class="ma-auto"
    max-width="500"
  >
    <v-card-title
      class="headline grey lighten-2 subtitle-1"
      primary-title
    >
      <span> Home Position Settings </span>
    </v-card-title>

    <v-card-text>
      <v-card-subtitle>Set Home & Base url</v-card-subtitle>
      <v-text-field
        v-model="home"
        hint="The URL of the home list."
        label="Home"
      ></v-text-field>
      <v-text-field
        v-model="base_url"
        hint="The URL that is the root of the absolute path in the link."
        label="Base url"
        :rules="homeRules"
      ></v-text-field>
      <v-row>
        <v-btn 
          @click='reset'
          text
        >
          <v-icon>mdi-eraser</v-icon>
          reset edits
        </v-btn><!-- Reset Button -->
      </v-row>
      <v-row>
        <v-btn
          @click='restore'
          text
        >
          <v-icon>mdi-factory</v-icon>
          restore to default
        </v-btn><!-- Reset Button -->
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="primary"
        @click="apply"
      >
        <v-icon>mdi-check-bold</v-icon>
        apply
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: function(){
    return {
      home: this.$home,
      base_url: this.$base_url,
      homeRules: [
        v => !!v || 'URL is required',
        v => (v && v.length <= 256) || 'URL must be less than 256 characters',
        /* eslint no-useless-escape: 0 */
        v => /https?:.*/.test(v) || 'URL should start with "http:" or "https:"',
        v => /https?:[0-9]*\/\/.*/.test(v) || 'URL should include "//"',
      ]
    }
  },
  methods: {
    reset(){
      this.home = this.$home
      this.base_url = this.$base_url
    },
    restore(){
      this.home = "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main/home"
      this.base_url = "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main"
    },
    apply(){
      this.$home = this.home
      localStorage.home = this.home
      this.$base_url = this.base_url
      localStorage.base_url = this.base_url
    }
  },
  mounted(){
    this.reset()
  }
};
</script>