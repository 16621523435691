<template>
  <v-list-item
    @click="move"
  >
    <v-list-item-content>
      <v-list-item-title v-text="item.text"></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  components: {
  },
  props: {
    item: Object,
  },
  data: () => ({
  }),
  methods: {
    move(){
      console.log("$props", this.$props)
      this.$emit("move-request", this.$props.item.link)
    }
  }
}
</script>
