// for Global Mixin
// https://v2.vuejs.org/v2/guide/mixins.html?redirect=true#Global-Mixin

import Vue from 'vue'

export default {
  init: () => {
    let globalData = new Vue({
      data: {
        /* eslint no-prototype-builtins: 0 */
        // eslint no-prototype-builtins
        $home: localStorage.hasOwnProperty('home') ?    
          localStorage.home :
          "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main/home",
        // eslint no-prototype-builtins
        $base_url: localStorage.hasOwnProperty('base_url') ?
          localStorage.base_url :
          "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main",
      }
    });
    let computed = {
      // home path to link
      $home: {
        get: function () { return globalData.$data.$home },
        set: function (newHome) { globalData.$data.$home = newHome; }
      },
      // base URL to make new path to link
      $base_url: {
        get: function () { return globalData.$data.$base_url },
        set: function (newBase_url) { globalData.$data.$base_url = newBase_url; }
      },
    };
    return computed
  },
}