<template>
      <v-container>
        <v-row>
          <v-col
            cols="4" 
            class="font-weight-medium caption"
          >
            Pause Resume
          </v-col>
          <v-btn class="ma-2" @click='send("pause")'><v-icon>pause</v-icon>/<v-icon>play_circle</v-icon></v-btn>
        </v-row>
        <v-row>
          <v-col
            cols="4" 
            class="font-weight-medium caption"
          >
            Stop
          </v-col>
          <v-btn class="ma-2 icon" @click='send("stop")'><v-icon>stop</v-icon></v-btn>
        </v-row>
        <v-row>
          <v-col
            cols="4" 
            class="font-weight-medium caption"
          >
            Volume
          </v-col>
            <v-btn class="ma-2 icon" @click='send("decreaseVolume")'><v-icon>volume_down</v-icon></v-btn>
            <v-spacer/>
            <v-btn class="ma-2 icon" @click='send("increaseVolume")'><v-icon>volume_up</v-icon></v-btn>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            class="font-weight-medium caption"
          >
            Seek Small
          </v-col> 
          <v-btn class="ma-2 icon" @click='send("seekBackSmall")'><v-icon>fast_rewind</v-icon></v-btn>
          <v-spacer/>
          <v-btn class="ma-2 icon" @click='send("seekForwardSmall")'><v-icon>fast_forward</v-icon></v-btn>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            class="font-weight-medium caption"
          >
            Seek Large
          </v-col>
          <v-btn class="ma-2 icon" @click='send("seekBackLarge")'><v-icon>fast_rewind</v-icon></v-btn>
          <v-spacer/>
          <v-btn class="ma-2 icon" @click='send("seekForwardLarge")'><v-icon>fast_forward</v-icon></v-btn>
        </v-row>
      </v-container>  
</template>

<script>
  import axios from 'axios'
  var sprintf = require('sprintf-js').sprintf

  export default {
    data: () => ({
//      urlstr: "",
    }),
    methods: {
      send: async function(action){
        console.log("action", action)
        const url = sprintf("/%s", action)
        const res = await axios.get(url)
        console.log("res", res)
      },
    },
/*
    mounted(){
      this.send("connect")
    }
*/
  }
</script>

<style>
.icon {
  min-width: 40px !important;
}
</style>