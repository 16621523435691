<template>
  <div>
    <div id="player2"></div>

    <v-card
      class="mx-auto"
      max-width="500"
    >
      <v-card-title>
        <v-progress-circular
          :value="20"
          indeterminate
          v-if="!isExpanded"
          class="mr-4"
        />
        {{title}}
      </v-card-title>

      <v-expansion-panels
        v-model="openedPanel" 
        accordion tile
      >
        <v-expansion-panel
          v-for="(item, i) in items"
          :key="i"
        >
          <Content v-if='"content" in item' :item="item"/>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import Content from "@/components/Content.vue"
import PlayBackPosition from "@/mixins/playbackposition"
import YTPlayList from "@/mixins/ytplaylist"
export default {
  props: {
  },
  components: {
    Content,
  },
  mixins: [PlayBackPosition, YTPlayList],
  data: () => ({
    title: "",
    items: [],
    contents: [],
    openedPanel: "",
    isExpanded: false,
    ytplayer: null,
  }),
  methods: {
    setURL(query, params){
      console.log("this", this)
      console.log("params", params)
      this.expandPlayList(params.id)
      if (query) {
        console.log("query",query)
        if ('title' in query){
          this.title = query.title
        }
      }
    },
    async setItems(){
      console.log("setItems")
      console.log("this", this)
      this.contents.forEach((item)=>{
        this.items.push({content: item})
      })
      this.isExpanded = true

      // update items with the status of viewing 
      this.AskLatestPlayBackPositions()

    }
  },
/*  watch: {
    $route(to) {
      console.log("to",to)
      this.setURL(to.query, to.params)
    }
  },*/
  mounted: function(){
    console.log("route", this.$route)
    this.setURL(this.$route.query, this.$route.params)
  }
}
</script>